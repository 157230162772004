import { ComponentType } from "react";

import { Optional } from "common/utils/types";

/**
 * Matches core CorePagination.get_paginated_response,
 * with max_page optionally added in clients
 * ClinicClientListCreateView._custom_paginator
 */
export interface IPaginator {
  count: number;
  page_start_index: number;
  page_end_index: number;
  page: number;
  page_size: number;
  max_page?: number;
}

/** matches accounts ProfileMarketingEmailUnsubscribeSerializer */
export type MarketingEmailUnsubscribe = {
  id: number;
  reason: Optional<UnsubscribeReason>;
  reason_other: Optional<string>;
};

/** Matches CommonProfileSerializer */
export type IProfile = {
  id: number;
  email: Optional<string>;
  email_display: Optional<string>;
  phone_number: Optional<number>;
  phone_number_display: Optional<string>;
  last_seen: Optional<string>;
  last_seen_display: string;
  initials: string;
  has_completed_signup: boolean;
};

// This a clinic app specific type, included in this common file to prevent
// a circular dependency
/** matches accounts ClinicClientProfileUpdateSerializer */
export interface IClinicProfileUpdate extends IBaseProfileUpdate {
  phone_number?: string;
  drivers_license_number?: string;
  drivers_license_state?: string;
  gender?: string;
  date_of_birth?: string;
}

// This a client app specific type, included in this common file to prevent
// a circular dependency
/** Matches ClientProfileUpdateSerializer & ClientUserUpdateSerializer */
export interface IClientProfileUpdate extends IBaseProfileUpdate {
  phone_number?: string;
  drivers_license_number?: string;
  drivers_license_state?: string;
  gender?: string;
  date_of_birth?: string;
}
export interface ICommonProfileUpdate extends IClientProfileUpdate, IClinicProfileUpdate {}

/** Matches carts InvalidCheckoutEntity.PROFILE.required_checkout_fields */
export type ProfileMissingCheckoutField = Partial<keyof ICommonProfileUpdate>;

/** matches ProfileDetailSerializer */
export interface ProfileDetail extends IProfile {
  marketing_email_unsubscribe: Optional<MarketingEmailUnsubscribe>;
  is_subscribed_to_messages: boolean;
  can_change_message_preference: boolean;
  missing_checkout_fields: ProfileMissingCheckoutField[];
}

/** matches ClientUserSerializer */
export interface ClientUser {
  id: number;
  drivers_license_number: Optional<string>;
  drivers_license_state: Optional<string>;
  gender: Optional<ProfileGender>;
  date_of_birth: Optional<string>;
  full_name: string;
  first_name: Optional<string>;
  last_name: Optional<string>;
}

/** matches accounts MarketingEmailUnsubscribeUpdateSerializer */
interface MarketingEmailUnsubscribeUpdate {
  reason: UnsubscribeReason | "";
  reason_other: string;
}

/** Matches BaseProfileUpdateSerializer */
export interface IBaseProfileUpdate {
  first_name?: string;
  last_name?: string;
  email?: string;
  marketing_email_unsubscribe?: MarketingEmailUnsubscribeUpdate | null;
  is_subscribed_to_messages?: boolean;
}

/** Matches CommonDoctorSerializer */
export type Doctor = {
  id: number;
  first_name: string;
  last_name: string;
  name_display: string;
  image_url: string;
};

/** Matches core.constants PatientKind */
export enum PatientKind {
  Canine = "CANINE",
  Feline = "FELINE",
  Equine = "EQUINE",
  Avian = "AVIAN",
  Bovine = "BOVINE",
  Ferret = "FERRET",
  Rabbit = "RABBIT",
  Rodent = "RODENT",
  Reptile = "REPTILE",
  Amphibian = "AMPHIBIAN",
  Fish = "FISH",
  Other = "OTHER",
  Unspecified = "UNSPECIFIED",
}

export const PatientKindDisplayNames: Record<PatientKind, string> = {
  [PatientKind.Canine]: "Canine",
  [PatientKind.Feline]: "Feline",
  [PatientKind.Equine]: "Equine",
  [PatientKind.Avian]: "Avian",
  [PatientKind.Bovine]: "Bovine",
  [PatientKind.Ferret]: "Ferret",
  [PatientKind.Rabbit]: "Rabbit",
  [PatientKind.Rodent]: "Rodent",
  [PatientKind.Reptile]: "Reptile",
  [PatientKind.Amphibian]: "Amphibian",
  [PatientKind.Fish]: "Fish",
  [PatientKind.Other]: "Other",
  [PatientKind.Unspecified]: "Unspecified",
};

/** Matches clients PatientStatus */
export enum PatientStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Surrendered = "SURRENDERED",
  Deceased = "DECEASED",
}

/** Matches clients ClientStatus */
export enum ClientStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

/* Empty enum representing PatientColor Backend enum * */
export enum PatientColorValue {}

/** Matches clients PatientColorData */
export type PatientColor = {
  value: PatientColorValue;
  label: string;
};

/** Matches core.constants SexKind */
export enum SexKind {
  Male = "MALE",
  Female = "FEMALE",
  Unknown = "UNKNOWN",
}

/** Matches core.constants ProfileGender */
export enum ProfileGender {
  Male = "MALE",
  Female = "FEMALE",
}

/** matches WeightUnitOfMeasure */
export enum WeightUnitOfMeasure {
  Pound = "POUND",
  Kilogram = "KILOGRAM",
  Gram = "GRAM",
  Ounce = "OUNCE",
}

/** Matches carts InvalidCheckoutEntity.PATIENT.required_checkout_fields */
export type PatientMissingCheckoutField = Partial<keyof IPatientWrite>;

/** Matches clients CommonBasicPatientSerializer */
export type IPatient = {
  id: number;
  ui_string: string;
  description: string;
  /** pet's name: may be blank so `name_display` is recommended for reading in
   *  most contexts */
  name: string;
  name_display: string;
  weight_in_units: number | null;
  weight_unit_of_measure: WeightUnitOfMeasure | "" | null;
  weight_display: Optional<string>;
  birth_day: Optional<number>;
  birth_month: Optional<number>;
  birth_year: Optional<number>;
  deceased_date: Optional<string>;
  species: PatientKind;
  custom_species: Optional<string>;
  species_display: string;
  primary_breed: Optional<PatientBreed>;
  secondary_breeds: PatientBreed[];
  color: Optional<PatientColor>;
  custom_color: Optional<string>;
  custom_breed: Optional<string>;
  sex: SexKind;
  sex_display: string;
  allergies: string;
  age_display: Optional<string>;
  is_active: boolean;
  is_alive: boolean;
  missing_checkout_fields: PatientMissingCheckoutField[];
  client_id: number;
  universal_patient: number | null;
};

/** Matches clients PatientBreedData */
export type PatientBreed = {
  value: PatientBreedValue;
  label: string;
  species: PatientKind;
};

/* Empty enum representing PatientBreed Backend enum * */
export enum PatientBreedValue {}

/** Matches accounts CommonAddressSerializer */
export interface IAddress {
  id: number;
  name: string;
  street: string;
  address_one: string;
  address_two: string;
  city: string;
  state: string;
  zipcode: string;
  phone_number: Optional<number>;
  ui_string: string;
}

export interface IGeoAddress {
  address_one: string;
  address_two: string;
  city: string;
  state: string;
  zipcode: string;
}

/** Geographic coordinate pair in the form of [longitude, latitude] */
export type Coordinate = [number, number];

export enum PaymentProvider {
  MASTERCARD = "mastercard",
  VISA = "visa",
  AMEX = "amex",
  DISCOVER = "discover",
}

/** Matches payments StripeWalletTypes */
export enum WalletType {
  APPLE = "APPLE",
  GOOGLE = "GOOGLE",
  SAMSUNG = "SAMSUNG",
  MASTERPASS = "MASTERPASS",
  AMEX_EXPRESS = "AMEX_EXPRESS",
  VISA_CHECKOUT = "VISA_CHECKOUT",
}

export interface CardPaymentMethod {
  id: number;
  is_klarna: false;
  last_four: string;
  brand: PaymentProvider;
  name: string;
  wallet: WalletType | "";
  expiration_month: string;
  expiration_year: string;
  ui_string: string;
  alt_ui_string: string;
  short_ui_string: string;
}

export interface KlarnaPaymentMethod {
  id: number;
  is_klarna: true;
  last_four?: null;
  brand?: null;
  name: string;
  wallet?: "";
  expiration_month?: null;
  expiration_year?: null;
  ui_string: string;
  alt_ui_string: string;
  short_ui_string: string;
}

/** Matches payments CommonPaymentMethodSerializer */
export type IPaymentMethod = CardPaymentMethod | KlarnaPaymentMethod;

/** matches PaymentDeclineErrorData */
export type CardPaymentDeclineError = {
  card_decline_description: string;
  card_decline_next_steps: string;
};

/** Matches StripeCardDeclineCodeData */
export type StripeCardDeclineCode = {
  decline_code: string;
  description: string;
  next_steps: string;
};

/** Matches payments CommonClientSecretSerializer */
export interface ISetupIntent {
  client_secret: string;
}

// From app/subscriptions/constants
export enum FrequencyUnit {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

/** Matches subscriptions types.SubscriptionFrequency */
export interface SubscriptionFrequency {
  frequency: number;
  interval_unit: FrequencyUnit;
  display: string;
}

export type FullSortDirective<S extends string> = [S, "asc" | "desc"];

/** Either a [key, sort direction] tuple, or a single key to sort on. If no direction is provided, ascending is assumed */
export type SortDirective<S extends string> = FullSortDirective<S> | S;

export interface ListSearch {
  page: number;
  page_size: number;
  sort: string;
  q: string;
}

export type ImportStatement = () => Promise<{ default: ComponentType<any> }>;

/** Matches subscriptions UserSubscriptionAction */
export enum UserSubscriptionAction {
  Modify = "MODIFY",
  Pause = "PAUSE",
  Resume = "RESUME",
  Cancel = "CANCEL",
  OrderNow = "ORDER_NOW",
  Skip = "SKIP",
}

/** Matches subscriptions UISubscriptionStatus */
export enum UISubscriptionStatus {
  Active = "ACTIVE",
  Canceled = "CANCELED",
  Paused = "PAUSED",
  FailedPayment = "FAILED_PAYMENT",
  Imported = "IMPORTED",
}

export enum ItemSelectionKind {
  Flavor = "FLAVOR",
}

/** Matches catalog BasicCatalogImageSerializer */
export interface BasicCatalogImage {
  id: number;
  url: string;
  thumbnail_url: string;
  medium_url: string;
  large_url: string;
}

/** Matches catalog ProductLineGuaranteedAnalysisItemSerializer */
export type ProductLineGuaranteedAnalysisItem = {
  id: number;
  nutrient: string;
  minmax: string;
  amount: string;
};

/** Matches catalog BasicFlavorSelectionSerializer */
export interface FlavorSelection {
  id: number;
  display: string;
  value: string;
}

export type GroupedItemFlavors = {
  label: string;
  required: boolean;
  options: FlavorSelection[];
};

/* Matches vendors ItemPriceBreak  */
export interface ProductItemPriceBreak {
  high_qty: number;
  low_qty: number;
  price: number;
}

/** Matches catalog BasicItemSerializer */
export interface BasicItem {
  id: number;
  product_id: number;
  name: string;
  duration_unit: FrequencyUnit;
  duration_value: number;
  msrp: number;
  full_name: string;
  image: Optional<BasicCatalogImage>;
  product_line_relative_name: string;
  weight: number;
  recommended_frequency: SubscriptionFrequency;
  vetcove_item_url: string;
  eligible_for_autoship_discounts: boolean;
  rationale_required: boolean;
  is_backordered: boolean;
  primary_label: Optional<string>;
  secondary_label: Optional<string>;
  days_supply: number;
  is_preventative: boolean;
  subscription_frequency_options: SubscriptionFrequency[];
}

/** Matches catalog BasicItemAndFlavorOptionsSerializer */
export interface BasicItemAndFlavorOptions extends BasicItem {
  flavor_options: GroupedItemFlavors[];
}

/** Matches catalog CommonItemWithProductSerializer */
export interface IItemWithProduct extends BasicItem {
  product: IItemProduct;
}

/** Matches catalog CommonItemAndFlavorOptionsWithProductSerializer */
export interface IItemAndFlavorOptionsWithProduct extends BasicItemAndFlavorOptions {
  product: IItemProduct;
}

/** Matches catalog CommonBasicProductSerializer */
export interface IItemProduct {
  id: number;
  product_line: IItemProductLine;
  name: string;
  default_sig: string | null;
}

/** Matches catalog BasicProductLineSerializer */
export interface IItemProductLine {
  id: number;
  name: string;
  description: string;
  short_description: string;
  manufacturer: BasicManufacturer;
  categories: BasicCategory[];
  prescription_required: boolean;
  refrigeration_required: boolean;
  flexscriptions_enabled: boolean;
  is_controlled: boolean;
  is_eligible_for_prn: boolean;
}

export type TableProductLine = {
  name: string;
};

export type TableProduct = {
  product_line: TableProductLine;
};

export type TableItem = {
  name: string;
  product: TableProduct;
  product_line_relative_name: string;
  image: Optional<BasicCatalogImage>;
};

/** Matches catalog BasicManufacturerSerializer */
export interface BasicManufacturer {
  id: number;
  name: string;
}

/** Matches catalog BasicCategorySerializer */
export interface BasicCategory {
  id: number;
  name: string;
  level: CategoryLevel;
  parent: Optional<number>;
  image: Optional<string>;
  icon: CategoryIcon;
}

/** matches catalog CategoryWithParentSerializer */
export interface CategoryWithParent extends BasicCategory {
  parent_name: string;
}

/** Matches catalog/constants.py */
export enum CategoryLevel {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

/** Matches catalog/constants.py */
export enum CategoryIcon {
  Compound = "COMPOUND",
  Drug = "DRUG",
  Food = "FOOD",
  Preventative = "PREVENTATIVE",
  Supplement = "SUPPLEMENT",
  Other = "OTHER",
}

/** matches uploads UploadedImageSerializer */
export type UploadedImage = {
  id: number;
  original_url: string;
  thumbnail_url: string;
  medium_url: string;
  large_url: string;
};

/** Matches POST to ClinicUploadedImageCleanView */
export type UploadedImageCleanBody = {
  uploaded_image: number;
};

/** Matches clients CommonBasicPatientSerializer _writable_fields */
export type IPatientWrite = {
  name: string;
  sex: SexKind;
  weight_in_units: number | null;
  weight_unit_of_measure: WeightUnitOfMeasure | "";
  birth_day: number | null;
  birth_month: number | null;
  birth_year: number | null;
  deceased_date?: string | null;
  species: PatientKind;
  custom_species: string | null;
  color: PatientColor | null;
  allergies: string;
  // matches read typing for easy optimistic updates
  primary_breed: PatientBreed | null;
  secondary_breeds: PatientBreed[];
  custom_breed: string;
  status: PatientStatus;
  image_id: number | null;
};

/** Matches clients CommonBasicPatientSerializer _writable_fields */
export type IPatientWriteBody = Omit<
  IPatientWrite,
  "primary_breed" | "secondary_breeds" | "color"
> & {
  primary_breed: PatientBreedValue | "";
  secondary_breeds: PatientBreedValue[];
  color: PatientColorValue | "";
};

/** matches clients ClientUpdateSerializer */
export type IClientUpdateFields = {
  primary_address?: number;
  primary_payment_method?: number;
};

/** matches reviews ReviewProductLineSerializer */
export interface IReviewProductLine {
  id: number;
  name: string;
  manufacturer: BasicManufacturer;
  primary_category: BasicCategory;
  image?: BasicCatalogImage;
}

export type ReviewRating = 1 | 2 | 3 | 4 | 5;

/**
 * matches clinics features_controller get_features returned key values
 * and clinic constant FeatureKind
 */
export enum Feature {
  PreventativesCompliance = "PREVENTATIVES_COMPLIANCE",
  Fulfillments = "FULFILLMENTS",
  Shortcodes = "SHORTCODES",
  ItemPickups = "ITEM_PICKUPS",
  Handwryttens = "HANDWRYTTENS",
  Marketing = "MARKETING",
  Onboarding = "ONBOARDING",
  TrainingDocs = "TRAINING_DOCS",
  Rebates = "REBATES",
  StoreAnalytics = "STORE_ANALYTICS",
  LocationGroups = "LOCATION_GROUPS",
  Etailers = "ETAILERS",
  Procurement = "PROCUREMENT",
  Compounds = "COMPOUNDS",
  Communications = "COMMUNICATIONS",
  Inbox = "INBOX",
  SameDayDelivery = "SAME_DAY_DELIVERY",
  ZoetisPetcareRewards = "ZOETIS_PETCARE_REWARDS",
  Launch = "LAUNCH",
  ClinicUserProfileImages = "CLINIC_USER_PROFILE_IMAGES",
  RxRequiresPrescriberPincode = "RX_REQUIRES_PRESCRIBER_PINCODE",
  CUSTOM_PERMISSIONS = "CUSTOM_PERMISSIONS",
  ImportedRxDeclineReason = "IMPORTED_RX_DECLINE_REASON",
  StorefrontQrCode = "STOREFRONT_QR_CODE",
  Klarna = "KLARNA",
  PromotionalMarketingEmailPreviews = "PROMOTIONAL_MARKETING_EMAIL_PREVIEWS",
  OnboardingRxActiveOnlyFilters = "ONBOARDING_RX_ACTIVE_ONLY_FILTERS",
  ClinicClientMergeTool = "CLINIC_CLIENT_MERGE_TOOL",
  ClinicIncentiveCreateV2 = "CLINIC_INCENTIVE_CREATE_V2",
  SigValidator = "SIG_VALIDATOR",
  RxInitials = "RX_INITIALS",
  Employees = "EMPLOYEES",
  Collaterals = "COLLATERALS",
  PayoutsPage = "PAYOUTS_PAGE",
  ExternalPrescriptionRequest = "EXTERNAL_PRESCRIPTION_REQUEST",
  // Pims features
  Scheduler = "SCHEDULER",
  MedicalRecords = "MEDICAL_RECORDS",
  Inventory = "INVENTORY",
  PatientWeightTracking = "PATIENT_WEIGHT_TRACKING",
  PimsCommunications = "PIMS_COMMUNICATIONS",
  SpellCorrect = "SPELL_CORRECT",
  SmartEdit = "SMART_EDIT",
  SmartCompose = "SMART_COMPOSE",
  WorkInProgress = "WORK_IN_PROGRESS",
  RichEditorDebugging = "RICH_EDITOR_DEBUGGING",
  TranscriptionRecorderV2 = "TRANSCRIPTION_RECORDER_V2",
}

export type Features = Record<Feature, boolean>;

export interface IPaginatedQuery<T> {
  paginator: IPaginator;
  results: T[];
}

/** Matches communications UnsubscribeReason */
export enum UnsubscribeReason {
  TooManyNotifications = "TOO_MANY_NOTIFICATIONS",
  NoLongerClient = "NO_LONGER_CLIENT",
  NoLongerHasPets = "NO_LONGER_HAS_PETS",
  DoesNotUseStorefront = "DOES_NOT_USE_STOREFRONT",
  Other = "OTHER",
}

export type OptimisticCreate = {
  optimistic_id: number;
};

/** Matches the BundleRenderType enum defined in general/constants.py */
export enum BundleRenderType {
  EMBED = "embed",
  FULL = "full",
}
